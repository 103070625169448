/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

#splash {
  background-size: cover;
  height: 100vh;
}

#splash h1 {
  color: #fff;
}

.userName {
  float: right;
  margin-right: 15px;
  color: #fff;
}

.dead-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.wsp-dark-bg {
  background-color: #1e242b !important;
}

.wsp-dev-bg {
  background-color: #b10f00 !important;
}

.ant-menu-item-selected {
  background-color: #537898 !important;
}

.app-logo {
  height: 30px;
  display: inline-block;
}

.app-menu {
  display: inline-block;
  margin-left: 30px;
}

.btn-success {
  background-color: #52c41a;
  border-color: #52c41a;
  color: #fff;
}

.btn-danger {
  background-color: #f5222d;
  border-color: #f5222d;
  color: #fff;
}

.btn-info {
  background-color: #537898;
  border-color: #537898;
  color: #fff;
}

.btn-monitor {
  background-color: #ff9c60;
  border-color: #ff9c60;
  color: #fff;
}


.txt-white {
  color: #fff;
}


.txt-secondary {
  color: #537898;
}

.txt-label {
  color: #537898;
  margin-right: 20px;
}

/* Override of the default hover color on table*/
.ant-table-tbody>tr.ant-table-row-level-0:hover>td {
  background: #e6f7ff;
}

.btn-review {
  background-color: #5260ff;
  border-color: #5260ff;
  color: #fff;
}


.btn-delete {
  background-color: #222428;
  border-color: #222428;
  color: #fff;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)
}

.mb-3 {
  margin-bottom: 2em;
}